import {Controller} from "stimulus"
import apiClient from "../api/apiClient"

/**
 * お気に入り用のコントローラ
 * TODO: 全体的にJSのエラー処理の方法がまだ仕様的に決められていない。
 */
export default class extends Controller {
  static targets = ["fav"]

  /**
   * お気に入りを削除する
   * @param event
   * @returns {Promise<AxiosResponse<T>>}
   */
  async delete(event) {
    const id = event.target.dataset.id
    return await apiClient.delete(`me/healthy_product_favorites/${id}`)
  }

  /**
   * お気に入りを削除しての要素を削除する
   * @param event
   */
  remove(event) {
    const id = event.target.dataset.id
    this.delete(event).then(resolve => {
      this.removeTableRow(id)
    })
  }

  /**
   * お気に入りを追加する
   * @param event
   */
  add(event) {
    const id = event.target.dataset.id
    apiClient.post(`me/healthy_product_favorites/${id}`)
  }

  /**
   * チェックの状態によってお気に入りを追加/削除する。
   * 削除した場合はIDの要素を削除する。
   * @param event
   */
  switchAndRemove(event) {
    this.favTarget.checked ? this.add(event) : this.remove(event)
  }

  /**
   * チェックの状態によってお気に入りを追加/削除する
   * @param event
   */
  switch(event) {
    this.favTarget.checked ? this.add(event) : this.delete(event)
  }

  /**
   * 指定されたお気に入りIDのテーブル行を削除する
   * @param id
   */
  removeTableRow(id) {
    $(`#product-lists__table-item-${id.toString()}`).remove()
  }
}
