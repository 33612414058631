
import {Controller} from "stimulus"
import apiClient from "../api/apiClient"

export default class extends Controller {


  static targets = [
    "id", "stoppage", "isRakuten",
    "stock_inAmazon", "stockoutAmazon", "amazonId",
    "stock_inYahoo", "stockoutYahoo", "yahooId",
    "stock_inRakuten", "stockoutRakuten", "rakutenId",
    "stock_inOther", "stockoutOther", "otherId",
    "urlUpdateAnnounceOutput", "stoppageUpdateAnnounceOutput",
    "singleCrawl", "resultId", "resultName", "resultStatus", "resultResStatus", "resultException"
  ]

  async sendChecked() {
    var singleCrawlTargets = [];
    for(var i = 0; i < this.singleCrawlTargets.length; i++) {
      var target = this.singleCrawlTargets[i];
      var target_healthy_product_id = this.idTargets[i].value;
      var checked = target.checked;
      if(checked) {
        singleCrawlTargets.push(target_healthy_product_id);
      }
    }
    var targetUrl = this.isRakutenTargets[0] ? 'api_search_single/for_rakuten' : 'api_search_single/for_yahoo'
    const crawlProducts = await apiClient.post(targetUrl, {
      crawl_healthy_product_ids: singleCrawlTargets
      }
    )
    return crawlProducts.data;
  }

  checkedCrawl() {
    this.sendChecked().then(resolve => {
      this.announceUpdateCrawlingStatus(resolve)
    });
  }

  announceUpdateCrawlingStatus(updateCrawlingStatus) {
    var updated_product_names = updateCrawlingStatus['crawl_healthy_product_ids']['name'];
    var updated_product_none = updateCrawlingStatus['crawl_healthy_product_ids']['name'] == '更新対象なし' ? '更新対象はありません。' : null;
    // jsでの実行結果を結果一覧にappendする
    this.urlUpdateAnnounceOutputTarget.innerHTML = updated_product_none ? updated_product_none : `${updated_product_names}を更新しました。`;
    this.urlUpdateAnnounceOutputTarget.className += "alert alert-warning";
  }
  checkInputForStocks() {
    for(var i = 0; i < this.stock_inAmazonTargets.length; i++) {
      var target = this.stock_inAmazonTargets[i];
      if(target.value === 'true') {
        target.checked = true;
      } else {
        target.checked = false;
      }
    }
    for(var i = 0; i < this.stock_inYahooTargets.length; i++) {
      var target = this.stock_inYahooTargets[i];
      if(target.value === 'true') {
        target.checked = true;
      } else {
        target.checked = false;
      }
    }
    for(var i = 0; i < this.stock_inRakutenTargets.length; i++) {
      var target = this.stock_inRakutenTargets[i];
      if(target.value === 'true') {
        target.checked = true;
      } else {
        target.checked = false;
      }
    }
    for(var i = 0; i < this.stock_inOtherTargets.length; i++) {
      var target = this.stock_inOtherTargets[i];
      if(target.value === 'true') {
        target.checked = true;
      } else {
        target.checked = false;
      }
    }
  }
}
