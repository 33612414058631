import {Controller} from "stimulus"
import apiClient from "../api/apiClient"

export default class extends Controller {


  static targets = [
    "id", "stoppage",
    "stock_inAmazon", "stockoutAmazon", "amazonId",
    "stock_inYahoo", "stockoutYahoo", "yahooId",
    "stock_inRakuten", "stockoutRakuten", "rakutenId",
    "stock_inOther", "stockoutOther", "otherId",
    "urlUpdateAnnounceOutput", "stoppageUpdateAnnounceOutput"
  ]

  async stoppage() {
    var stoppageTargets = [];
    for(var i = 0; i < this.idTargets.length; i++) {
      var target = this.stoppageTargets[i];
      var checked = target.checked;
      if(checked) {
        stoppageTargets.push([this.idTargets[i].value, true]);
      } else {
        stoppageTargets.push([this.idTargets[i].value, false]);
      }
    }
    const stoppages = await apiClient.post('healthy_products/update_stoppages', {
        stoppage_with_id: stoppageTargets
      }
    )
    return stoppages.data;
  }

  async visuallyCheck() {
    var visuallyCheck = [];
    for(var i = 0; i < this.amazonIdTargets.length; i++) {
      var stockTarget = this.stock_inAmazonTargets[i];
      var stockoutTarget = this.stockoutAmazonTargets[i];
      var stockChecked = stockTarget.checked;
      var stockoutChecked = stockoutTarget.checked;
      var aValue = this.amazonIdTargets[i].value;

      // チェックのステータスが同じであれば、unsetのステータスを渡す
      if(aValue == 'false') {
        continue;
      } else if(stockChecked && stockoutChecked || !stockChecked && !stockoutChecked) {
        visuallyCheck.push([this.amazonIdTargets[i].value, 'not_set']);
      } else {
        if(stockChecked) {
          visuallyCheck.push([this.amazonIdTargets[i].value, 'stock_in']);
        } else {
          visuallyCheck.push([this.amazonIdTargets[i].value, 'stockout']);
        }
      }
    }

    for(var i = 0; i < this.yahooIdTargets.length; i++) {
      var stockTarget = this.stock_inYahooTargets[i];
      var stockoutTarget = this.stockoutYahooTargets[i];
      var stockChecked = stockTarget.checked;
      var stockoutChecked = stockoutTarget.checked;
      var yValue = this.yahooIdTargets[i].value;

      // チェックのステータスが同じであれば、unsetのステータスを渡す
      if(yValue == 'false') {
        continue;
      } else if(stockChecked && stockoutChecked || !stockChecked && !stockoutChecked) {
        visuallyCheck.push([this.yahooIdTargets[i].value, 'not_set']);
      } else {
        if(stockChecked) {
          visuallyCheck.push([this.yahooIdTargets[i].value, 'stock_in']);
        } else {
          visuallyCheck.push([this.yahooIdTargets[i].value, 'stockout']);
        }
      }
    }

    for(var i = 0; i < this.rakutenIdTargets.length; i++) {
      var stockTarget = this.stock_inRakutenTargets[i];
      var stockoutTarget = this.stockoutRakutenTargets[i];
      var stockChecked = stockTarget.checked;
      var stockoutChecked = stockoutTarget.checked;
      var rValue = this.rakutenIdTargets[i].value;

      // チェックのステータスが同じであれば、unsetのステータスを渡す
      if(rValue == 'false') {
        continue;
      } else if(stockChecked && stockoutChecked || !stockChecked && !stockoutChecked) {
        visuallyCheck.push([this.rakutenIdTargets[i].value, 'not_set']);
      } else {
        if(stockChecked) {
          visuallyCheck.push([this.rakutenIdTargets[i].value, 'stock_in']);
        } else {
          visuallyCheck.push([this.rakutenIdTargets[i].value, 'stockout']);
        }
      }
    }

    for(var i = 0; i < this.otherIdTargets.length; i++) {
      var stockTarget = this.stock_inOtherTargets[i];
      var stockoutTarget = this.stockoutOtherTargets[i];
      var stockChecked = stockTarget.checked;
      var stockoutChecked = stockoutTarget.checked;
      var oValue = this.otherIdTargets[i].value;

      // チェックのステータスが同じであれば、unsetのステータスを渡す
      if(oValue == 'false') {
        continue;
      } else if(stockChecked && stockoutChecked || !stockChecked && !stockoutChecked) {
        visuallyCheck.push([this.otherIdTargets[i].value, 'not_set']);
      } else {
        if(stockChecked) {
          visuallyCheck.push([this.otherIdTargets[i].value, 'stock_in']);
        } else {
          visuallyCheck.push([this.otherIdTargets[i].value, 'stockout']);
        }
      }
    }

    const ret = await apiClient.post('healthy_products/update_visually_check', {
        visually_check_with_id: visuallyCheck
      }
    )
    return ret.data;
  }

  sendFlag() {
    this.visuallyCheck().then(resolve => {
      this.announceVisuallyCheckStatus(resolve)
    });
  }

  sendStoppageFlag() {
    this.stoppage().then(resolve => {
      this.announceStoppage(resolve)
    });
  }

  announceStoppage(stoppagesWithNames) {
    var stoppaged_names = stoppagesWithNames['stoppage_with_id']['stoppaged'];
    var unstoppaged_names = stoppagesWithNames['stoppage_with_id']['unstoppaged'];

    var text = `<b>クローラー停止(生産中止)</b>: ${stoppaged_names.length === 0 ? '更新対象なし' : stoppaged_names} <br>
                <b>クローラー有効(在庫あり)</b>: ${unstoppaged_names.length === 0 ? '更新対象なし' : unstoppaged_names} のステータスを更新しました。<br>
                <b>※定期クローラー列はブラウザを更新して確認してください。</b>`;

    this.stoppageUpdateAnnounceOutputTarget.innerHTML = text;
    this.stoppageUpdateAnnounceOutputTarget.className += "alert alert-warning";
  }

  announceVisuallyCheckStatus(visuallyCheckWithNames) {
    var not_set_name = visuallyCheckWithNames['visually_check_with_id']['not_set'];
    var stock_in_name = visuallyCheckWithNames['visually_check_with_id']['stock_in'];
    var stockout_name = visuallyCheckWithNames['visually_check_with_id']['stockout'];
    var text = `<b>未設定</b>: ${not_set_name.length == 0 ? '更新対象なし' : not_set_name} <br><b>在庫あり</b>: ${stock_in_name.length == 0 ? '更新対象なし' : stock_in_name} <br><b>在庫なし</b>: ${stockout_name.length == 0 ? '更新対象なし' : stockout_name}の品切れステータスを更新しました。`;

    this.urlUpdateAnnounceOutputTarget.innerHTML = text;
    this.urlUpdateAnnounceOutputTarget.className += "alert alert-warning";
  }
  checkInputForStocks() {
    for(var i = 0; i < this.stock_inAmazonTargets.length; i++) {
      var target = this.stock_inAmazonTargets[i];
      if(target.value === 'true') {
        target.checked = true;
      } else {
        target.checked = false;
      }
    }
    for(var i = 0; i < this.stock_inYahooTargets.length; i++) {
      var target = this.stock_inYahooTargets[i];
      if(target.value === 'true') {
        target.checked = true;
      } else {
        target.checked = false;
      }
    }
    for(var i = 0; i < this.stock_inRakutenTargets.length; i++) {
      var target = this.stock_inRakutenTargets[i];
      if(target.value === 'true') {
        target.checked = true;
      } else {
        target.checked = false;
      }
    }
    for(var i = 0; i < this.stock_inOtherTargets.length; i++) {
      var target = this.stock_inOtherTargets[i];
      if(target.value === 'true') {
        target.checked = true;
      } else {
        target.checked = false;
      }
    }
  }
}
