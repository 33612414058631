import cookies from 'js-cookie'
import axios from 'axios'

// TODO: もう少し整理する
const protocol = function () {
  if (process.env.CUSTOM_DOMAIN === 'localhost') {return 'http'}
  return 'https'
}

const PROTO = protocol()

const baseUrl = function () {
  if (process.env.NODE_ENV === 'development' && process.env.APP_MODE === 'cl') {
    return `${PROTO}://${process.env.CUSTOM_DOMAIN}:${process.env.HTTP_PORT}/api/v2`
  } else if(process.env.NODE_ENV === 'development' ){
    return `${PROTO}://${process.env.CUSTOM_DOMAIN}:${process.env.HTTP_PORT}/api/v1`
  } else {
    return `${PROTO}://${process.env.CUSTOM_DOMAIN}/api/v1`
  }
}
const BASE_URL = baseUrl()
const DEBUG = (process.env.NODE_ENV === 'development')

const apiClient = axios.create({
    baseURL: BASE_URL,
    timeout: 10000
})
apiClient.interceptors.request.use(
    config => {
        if (DEBUG) {
            console.group(`>>> REQ [${config.method.toUpperCase()}] ${config.url}`)
            console.dir(config)
            console.groupEnd()
        }
        config.headers['api-token'] = process.env.API_TOKEN
        config.headers['access-token'] = cookies.get('access-token')
        config.headers['client'] = cookies.get('client')
        config.headers['uid'] = cookies.get('uid')
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
// response interceptor
apiClient.interceptors.response.use(
    response => {
        if (DEBUG) {
            console.group(`<<< RES [${response.config.method.toUpperCase()}] ${response.config.url}`)
            console.dir(response)
            console.groupEnd()
        }
        return response
    },
    error => {
        return Promise.reject(error)
    }
)

export default apiClient
