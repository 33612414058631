import { Controller } from "stimulus"

export default class extends Controller {
  // 外部リンク設定
  connect() {
    this.element.querySelectorAll('a').forEach(function(link) {
      if (link.host.match(document.domain)) return;

      link.target = '_blank';
      link.classList.add('app__tmp-target-blank');
    })
  }
}
