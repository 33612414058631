import {Controller} from "stimulus"
import apiClient from "../api/apiClient"

export default class extends Controller {
  static targets = ["source", "output"]

  async substances(){
    const ret = await apiClient.post('additive_substances', {
        raw_material: this.sourceTarget.value
      }
    )
    return ret.data.additive_substances
  }

  highlight(event) {
    this.substances().then(resolve => {
      this.highlightSubstances(resolve)
    })
  }

  highlightSubstances(substancesStr) {
    const regexp = new RegExp(substancesStr.split(',').join('|'), 'g')
    this.outputTarget.innerHTML = this.sourceTarget.value.replace(regexp, "<span class=\"text-danger\">$&</span>")
  }
}
