import { Controller } from 'stimulus'
import Sortable from 'sortablejs'
import Rails from '@rails/ujs'

export default class extends Controller {
  initialize() {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      onEnd: this.dragend.bind(this)
    })
  }

  dragend(event) {
    const nodes = this.element.querySelectorAll('[data-id]');
    const orderArray = Array.from(nodes).map(el => el.getAttribute('data-id')).reverse();
    Rails.ajax({
      type: 'PATCH',
      url: this.element.dataset.adminsSortInformationUrl,
      data: `${this.element.dataset.adminsSortInformationParam}[order]=${orderArray}`,
      success: (data, status, xhr) => {
        if(xhr.status >= 400) {
          this.error();
        }
      },
      error: () => {
        this.error();
      }
    })
  }

  error(){
    const confirmResult = window.confirm('表示順の更新が失敗しました。再読み込みを行ってください');
    if (confirmResult) location.reload();
  }
}
