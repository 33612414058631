import { Controller } from "stimulus"

// .product-lists-table__product
// 原材料名 もっと読むの設定
export default class extends Controller {
  static targets = [ "item", "btn" ]

  connect() {
    this.btnTarget.dataset.height = false;
    this.itemTarget.dataset.height = false;
    const uniqueItem = document.getElementsByClassName('raw-material-name__item')[0];
    const uniqueItemStyles = window.getComputedStyle(uniqueItem);
    const uniqueItemLineHeight = uniqueItemStyles.getPropertyValue('line-height');
    const uniqueItemLineHeightNum = Number(uniqueItemLineHeight.replace('px', ''));
    const numberOfRowsToDisplay = 4;
    const targetItemRows = Number(this.itemTarget.offsetHeight / parseInt(uniqueItemLineHeightNum));
    if (targetItemRows >= (numberOfRowsToDisplay + 1)) {
      // 指定した行数よりも多ければtrueにする
      this.btnTarget.dataset.height = true;
      this.itemTarget.dataset.height = true;
    }
  }
}
